.articleBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 57%;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 161px;
  height: 38px;
  margin: 20px 0 0 0;
  padding: 10px 18px;
  background: #0e6ab0;
  border: 1px solid #0e6ab0;
  border-radius: 4px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: #ffffff;
  cursor: pointer;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}

.btn:hover {
  background: #d8e7f2;
  color: #0e6ab0;
}
.btnactiveslot {
  background: #d8e7f2;
  color: #0e6ab0;
  width: 200px;
  height: 42px;
  margin: 0;
  padding: 0;
}
.btnSaveArticle {
  width: 200px;
  height: 42px;
}

.btnSubmitArticle .buttonOnboardAuthor {
  width: 200px;
  height: 42px;
}

.btnAddSlot {
  /* width: 200px; */
  height: 42px;
  /* margin-left: 7%;
    margin-right: 3%; */
  margin-top: 0;
}
.Slotbtn {
  width: 200px;
  height: 42px;
  margin: 0 0 20px 0;
  padding: 0;
}
.Block {
  width: 110px;
  height: 28px;
  margin: 0;
  padding: 0;
}
.btnhide {
  background: #d8e7f2;
  color: #0e6ab0;
  cursor: not-allowed;
}
.Submit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 161px;
  height: 38px;
  margin: 0 !important;
  padding: 10px 18px;
  background: #0e6ab0;
  border: 1px solid #0e6ab0;
  border-radius: 4px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: #ffffff;
  cursor: pointer;
  box-sizing: border-box;
}
