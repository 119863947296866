.uploadFile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    border-radius: 10px;
    background: #fff;
    border: 2px dashed #0B6AB0;
    padding: 10px;
    box-sizing: border-box;
    
}

.uploadField {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.afterUpload {
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
}

.cancel {
    position: absolute;
    right: -25px;
    top: -25px;
    cursor: pointer;
}
  
.wrapper input {
    display: none;
    
}

.icon {
    cursor: pointer;
}

.uploadFile input {
    display: none;
}

.uploadFile h3 {
    padding: 0;
    margin: 20px 0 10px 0;
    font-size: 14px;
    color: #7F7F7F;
}
  
.imageUpload1 {
    width: 500px;
    height: 300px;
}

.videoUpload1 {
    width: 500px;
    height: 300px;
}

.thmbnailUpload1 {
    width: 300px;
    height: 200px;
}

.articleImgUpload1 {
    width: 400px;
    height: 300px;
}
.avatarUpload {
    width: 80px;
    height: 80px;
    border-radius: 100%;

}

.avatarUpload img {
    border-radius: 100%;
}