.parentLogin {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.LoginForm {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    height: 100%;
    margin: 20px 0px 0px 0px;
    padding: 20px;
    border-radius: 10px;
    background: white;
}