.welcome {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    
}

h1 {
    font-size: 44px;
    color: #7F7F7F;
}

h2 {
    color: #7F7F7F;
}