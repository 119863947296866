.parentSignup {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.signupForm {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    height: 100%;
    margin: 20px 0px 0px 0px;
    padding: 20px;
    border-radius: 10px;
    background: white;
}
.signUpButton {
    width:100%;
    border-radius: 20px;
}
.error{
    color:red;
    font-size: 12px;
}
#password {
    width: 600px;
    height: 47px;
    padding: 0 20px;
    margin: 20px 0 0 0;
    background: rgba(11, 106, 176, 0.1);
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 4px;
}