*:focus {
  outline: none;
}

.form_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  margin: 0 0 0 0;
  padding: 0px 0 0 0;
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
  /* width: 800px; */
  width: 100% !important;
  height: auto;
  margin: 0 0 0 0;
  padding: 20px;
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
}

.inputFile {
  display: none;
}

.icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #7f7f7f;
  box-sizing: border-box;
}
.text-area-component {
  color: #212f45;
  min-height: 50px;
  height: 90px;
  padding: 8px;
  overflow-y: scroll;
  border: none;
  resize: none;
  box-sizing: border-box;
}

#email,
#first_name,
#last_name,
#middle_name {
  width: 600px;
  height: 47px;
  padding: 0 20px;
  margin: 10px 0 0 0;
  background: rgba(11, 106, 176, 0.1);
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
}

#phone {
  width: 600px;
  height: 47px;

  padding: 0 20px;
  background: rgba(11, 106, 176, 0.1);
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
}

#bio {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 600px;
  height: 200px;
  margin: 10px 0 0 0;
  padding: 20px;
  background: rgba(11, 106, 176, 0.1);
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
}

#other_qualification,
#primary_qualification,
#zip_code,
#city,
#yearofexperience,
#assetsmanaged,
#consultationumber,
.inputTag,
#domain,
#address,
#title {
  width: 600px;
  height: 50px;
  margin: 10px 0 0 0;
  padding: 20px;
  background: rgba(11, 106, 176, 0.1);
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
}

.btnAuthor {
  width: 600px;
  height: 47px;
  margin: 20px 0 0 0;
  padding: 0px 170px;
  background: #0e6ab0;
  border: 1px solid #0e6ab0;
  border-radius: 4px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  box-sizing: border-box;
}

.btnAuthor:hover {
  background: rgb(141, 141, 150);
}

.form_container h2 {
  font-size: 24px;
  padding: 0;
  margin: 20px 0 10px 0;
  color: #7f7f7f;
}

.form_container .error_p {
  color: red;
}

#country {
  width: 50px;
  height: 100%;
  margin: 0 10px 0 0;
  border: none;
  background: rgba(11, 106, 176, 0.1);
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
}

.error_p {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.phone .error_p {
  margin: 0 0px 0 10px;
  font-size: 16px;
}

.side {
  margin: 10px 0 -7px 0;
  padding: 0;
  font-size: 14px;
  box-sizing: border-box;
}

.label {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.error {
  border: none !important;
  color: red;
}
.select1 {
  height: 100%;
}
.disabledBtn {
  cursor: not-allowed !important;
}
