body {
    /* display: flex;
    justify-content: flex-start;
    align-items: center; */
    padding: 0;
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    background-color: #f4f4f4;
}

.rc-time-picker-clear {
    position: absolute;
    right: 6px;
    cursor: pointer;
    overflow: hidden;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    /* top: 3px; */
    margin: 0;
}