.App {
  width: 100%;
  height: auto;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  overflow: hidden;
}

table,
th,
td {
  border: 1px solid white;
  border-collapse: collapse;
  text-align: start;
}

.mainBody {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 0;
  margin: 0px 0 0 0;
  box-sizing: border-box;
}

.tabContents {
  width: calc(100vw - 195px);
  height: auto;
  margin: 0px 0 0px 0px;
  padding: 20px 72px 20px 30px;
  box-sizing: border-box;
}

.rc-time-picker-clear {
  position: absolute;
  right: 14px;
  cursor: pointer;
  overflow: hidden;
  width: 20px;
  height: 39px;
  text-align: center;
  line-height: 20px;
  top: 10px;
  margin: 0;
}
.rc-time-picker-input {
  width: 65%;
  position: relative;
  display: inline-block;
  padding: 4px 7px;
  height: 39px !important;
  cursor: text;
  font-size: 12px;
  line-height: 1.5;
  color: #666;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
a:link {
  text-decoration: none;
}

.rmdp-input {
  border: 1px solid #c0c4d6;
  border-radius: 5px;
  margin: 1px 0;
  height: 30px !important;
  max-width: 185px !important;
  line-height: 1.5;
  color: rgb(102, 102, 102);
  background-color: rgb(255, 255, 255);
  background-image: none;
  border: 1px solid rgb(217, 217, 217);
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  padding: 2px 5px;
}

.css-levciy-MuiTablePagination-displayedRows
  .MuiTablePagination-displayedRows
  .displayedRows {
  display: none;
}
.MuiTablePagination-displayedRows {
  display: none;
}
