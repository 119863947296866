.articlePreview {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  margin: 0 0 20px 0;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-sizing: border-box;
}
.articlePreview img {
  /* width: 150px; */
  height: 100% !important ;
  border-radius: 4px;
}

.preview {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 0 0 20px;
  padding: 0px;
  box-sizing: border-box;
}

.preview h1 {
  margin: 0 0 10px 0;
  padding: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 42px;
  color: #000000;
}

.preview p {
  margin: 0;
  padding: 0;
}

.buttons {
  display: flex;
  justify-content: flex-end !important;
  align-items: center;
  width: 100%;
  /* margin: 20px 0 0px 0; */
  margin: 0 !important;
  box-sizing: border-box;
}

.buttons p {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 66px;
  height: 26px;
  margin: 0 10px 0 0px;
  padding: 0;
  background:#D8E7F2;
  border: 2px solid #e5e5e5;
  border-radius: 314px;
  color: #0E6AB0;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
}
.relative {
  position: relative;
  height: 100%;
}
.absolute {
  position: absolute;
  background-color: #0b6ab0;
  border-radius: 0 0 10px 0;
  padding: 5px 7px;
  color: aliceblue;
  /* width: 100%; */
  left: 0;
  top: 0;
}

.flexCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 10px;
}
.flexRow {
  display: flex;
  justify-content: flex-start;
}
.roundimg {
  height: 50px;
  width: 50px;
  border-radius: 40px;
}
