.header {
    position: fixed;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 93px;
    margin: 0;
    padding: 0 72px;
    background: #0B6AB0;
    z-index: 8000;
    box-sizing: border-box;
}

.prosperr {
    width: 124px;
    height: 36px;
    margin: 0;
    padding: 0;
    /* margin-left: 72px; */
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 151.17%;
    /* or 36px */
    text-transform: uppercase;
    color: #FFFFFF;
    cursor: pointer;
    text-decoration: none;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    cursor: pointer;
    box-sizing: border-box;
}
    
.login {
    margin: 0 20px 0 0;
    padding: 6px 17px;
    border: 1px solid rgb(204, 194, 194);
    border-radius: 2px;
    color: white;
    font-size: 14px;
    text-decoration: none;

}

.signup {
    border: 1px solid rgb(204, 194, 194);
    border-radius: 2px;
    padding: 6px 17px;
    color: white;
    font-size: 14px;
    background-color: #05395e;
    text-decoration: none;

}
    
.useremail {
    color: white;
    margin: 0 10px 0 0 ;
}    





