.modal {
  /* font-size: 12px; */
  background-color: #d8e7f2;
  border-radius: 10px;
  width: 100%;
}
.modal > .headerPop {
  /* width: 400px; */
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 20px;
}
.modal > .contentPop {
  width: 99%;
  padding: 10px 5px;
  /* height: 300px; */
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .closePop {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
.popup-content {
  margin: auto;
  /* background: rgb(255, 255, 255); */
  width: auto;
  padding: 5px;
}

.Appointmentbtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 161px;
  height: 38px;
  margin: 20px 0 0 0;
  padding: 10px 18px;
  background: #0e6ab0;
  border: 1px solid #0e6ab0;
  border-radius: 4px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: #ffffff;
  cursor: pointer;
  box-sizing: border-box;
}
